import { render, staticRenderFns } from "./ErrorPayment.vue?vue&type=template&id=6ba7e7e4&scoped=true&"
import script from "./ErrorPayment.vue?vue&type=script&lang=js&"
export * from "./ErrorPayment.vue?vue&type=script&lang=js&"
import style0 from "./ErrorPayment.vue?vue&type=style&index=0&id=6ba7e7e4&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6ba7e7e4",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VImg } from 'vuetify/lib/components/VImg';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
installComponents(component, {VBtn,VCard,VImg,VOverlay,VProgressCircular})
