<template>
  <div>
    <v-card class="pa-4 mt-5 pa-lg-7" elevation="1" rounded="lg">
      <v-card class="transparent" align="center" flat>
        <v-card class="pa-5 mr-3 mb-3 mt-11" width="600" align="center" flat>
          <v-img
            alt="Vuetify Logo"
            src="@/assets/img/payment/error.svg"
            width="150"
          />

          <h1 class="mt-10">An Error Occurred With Your Payment</h1>

          <p>Thank you for using our app.</p>

          <h5>We Love You!</h5>

          <v-btn
            color="btn btn-primary white--text mt-4"
            :to="{ name: 'DashboardInbox' }"
          >
            Home
          </v-btn>
        </v-card>
      </v-card>

      <!-- Overlay -->
      <v-overlay :value="overlay" z-index="1000">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
    </v-card>
  </div>
</template>

<script>
import { mapMutations } from "vuex";

export default {
  name: "DocumentManagementErrorPayment",

  data() {
    return {
      overlay: false,
    };
  },

  created() {
    // getUserDetails
    this.getUserDetails();
  },

  mounted() {},

  methods: {
    // mapMutations
    ...mapMutations({
      setUser: "auth/setUser",
    }),

    // getUserDetails
    async getUserDetails() {
      this.overlay = true;

      const res = await this.$helpers.getApiData("user-details");

      if (res.status == 200) {
        const data = res.data;

        this.setUser(data);
      }

      this.overlay = false;
    },
  },
};
</script>

<style lang="scss" scoped>
//
</style>
