<template>
  <div class="background">
    <ErrorPayment />
  </div>
</template>

<script>
import ErrorPayment from "@/components/elements/dashboard/payment/error-payment/ErrorPayment";

export default {
  name: "DocumentManagementErrorPayment",

  components: {
    ErrorPayment,
  },

  data() {
    return {};
  },

  mounted() {},

  methods: {},
};
</script>

<style lang="scss" scoped>
//
</style>
